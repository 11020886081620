import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import GoogleLogin from 'react-google-login';
import { connect } from "react-redux";
import "../../scss/home.scss";
import "../../scss/login.scss";
import io from "socket.io-client";
import { PROVIDERS } from "../../services/constants";
import { setUser } from "../../actions";
import * as Actions from "../../actions";
import { setCookie, getCookie } from "../../services/helpers";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      email: "",
      pwd: "",
      errors: null,
      userSignin:null,
      wooCustomerList:null
    };
  }
  responseGoogle = (data) => {
    var props=this.props;
    props.setUser(data.profileObj);
    if (data.tokenId) {
      setCookie("s-atk", data.tokenId, 0.2);
      props.setUser(data.profileObj);
      let nameArr=data.profileObj.name.split(" ");
      let usernameArr=data.profileObj.email.split("@");
      let customer = {
        email: data.profileObj.email,
        first_name:  nameArr[0],
        last_name: nameArr[1],
        username: usernameArr[0],
        billing: {
          first_name: nameArr[0],
          last_name: nameArr[1],
          company: "",
          address_1: "969 Market",
          address_2: "",
          city: "San Francisco",
          state: "CA",
          postcode: "94103",
          country: "US",
          email: data.profileObj.email,
          phone: "(555) 555-5555"
        },
        shipping: {
          first_name: nameArr[1],
          last_name: nameArr[1],
          company: "",
          address_1: "969 Market",
          address_2: "",
          city: "San Francisco",
          state: "CA",
          postcode: "94103",
          country: "US"
        }
      };
      props.createWooCustomers(customer);
      let route = localStorage.getItem("nextRoute");
      if (route) {
        if (route.length > 0) {
          localStorage.removeItem("nextRoute");
          props.history.push(route);
        } else {
          props.history.push("/");
        }
      } else {
        props.history.push("/");
      }
    }
  }

  change(e) {
    let state = this.state;
    state[e.currentTarget.name] = e.currentTarget.value;
    this.setState(state);
  }
  componentDidMount() {
    var c = getCookie("s-atk");
    let route = localStorage.getItem("nextRoute");
    this.props.getWooCustomerList();
    setTimeout(()=>{
      if(window.gapi){
        this.props.checkUserSignin(window.gapi);
      }
    },2000);
  }

  static getDerivedStateFromProps(props, state){

    if (props.wooCustomerList && props.userSignin && props.userSignin !== state.userSignin ) {
      let accessToken=props.userSignin.accessToken;
      setCookie("s-atk",accessToken, 0.2);
      props.setUser(props.userSignin);
      let first_name=props.userSignin.first_name;
      let last_name=props.userSignin.last_name;
      let username=props.userSignin.username;
      let checkUser=[];
      if(Object.keys(props.wooCustomerList).length > 0){
          let arrList=props.wooCustomerList;
        checkUser=arrList.filter((customer)=>{ return (props.userSignin.email==customer.email)});
      }
      if(checkUser.length < 0){
      let customer = {
        email: props.userSignin.email,
        first_name:  first_name,
        last_name: last_name,
        username: username,
        billing: {
          first_name: first_name,
          last_name: last_name,
          company: "",
          address_1: "969 Market",
          address_2: "",
          city: "San Francisco",
          state: "CA",
          postcode: "94103",
          country: "US",
          email:props.userSignin.email,
          phone: "(555) 555-5555"
        },
        shipping: {
          first_name: first_name,
          last_name: last_name,
          company: "",
          address_1: "969 Market",
          address_2: "",
          city: "San Francisco",
          state: "CA",
          postcode: "94103",
          country: "US"
        }
      }
      props.createWooCustomers(customer);
    }
     return {
       userSignin: props.userSignin

     };
   }
   return null;
  }
  show_errors() {
    if (this.state.errors) {
      return <div className="alert alert-danger">{this.state.errors}</div>;
    } else {
      return <div />;
    }
  }

  openPopup(e) {
    e.preventDefault();
    const width = 600,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const url = e.currentTarget.getAttribute("href");
    window.open(
      url,
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no,
      scrollbars=no, resizable=no, copyhistory=no, width=${width},
      height=${height}, top=${top}, left=${left}`
    );
  }
  render() {
    let name = null;
    if (this.props.user) {
      name = this.props.user.name;
    }
    return (
      <div className="signin-form pt-5">
        <Container>

          <Row>

            </Row>
            <Row>
            <Col md={6} className="offset-md-3">
              {name ? (
                <h4 className="text-center">You have already logged.</h4>
              ) : (
                <div>
                <GoogleLogin
                    clientId="68082392477-39auaug776rogf0iscbidfslh5rbp602.apps.googleusercontent.com"
                    buttonText="Sign in with Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />

                </div>
              )}
              {}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.get("user"),
    userSignin:state.get("user").userSigninDetails,
    wooCustomerDetails:state.get("user").wooCustomerDetails,
    wooCustomerList:state.get("user").listAllCustomers
  };
};
function mapDispatchToProps(dispatch) {
  return {
    setUser: user => dispatch(setUser(user)),
    createWooCustomers: customer => dispatch(Actions.createWooCustomers.request(customer)),
    getWooCustomer: data => dispatch(Actions.getWooCustomer.request(data)),
    getWooCustomerList:()=>dispatch(Actions.getWooCustomerList.request()),
    checkUserSignin: data => dispatch(Actions.checkUserSignin(data))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
