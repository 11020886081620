const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

//Action Types
export const GETWOOSHIPPINGZONES= createRequestTypes("GETWOOSHIPPINGZONES");
export const GETWOOSHIPPINGMETHODS= createRequestTypes("GETWOOSHIPPINGMETHODS");
export const GETWOOSHIPPINGZONEMETHODS= createRequestTypes("GETWOOSHIPPINGZONEMETHODS");
export const SINGLEWOOPRODUCT= createRequestTypes("SINGLEWOOPRODUCT");
export const CREATEWOOCUSTOMERS = createRequestTypes("CREATEWOOCUSTOMERS");
export const UPDATEWOOCUSTOMERS = createRequestTypes("UPDATEWOOCUSTOMERS");
export const GETWOOCUSTOMER = createRequestTypes("GETWOOCUSTOMER");
export const GETWOOCUSTOMERLIST = createRequestTypes("GETWOOCUSTOMERLIST");
export const WOOPRODUCTS = createRequestTypes("WOOPRODUCTS");
export const WOOPRODUCTSCATEGORIES = createRequestTypes("WOOPRODUCTSCATEGORIES");
export const CREATEWOOORDER = createRequestTypes("CREATEWOOORDER");
export const UPDATEWOOORDER = createRequestTypes("UPDATEWOOORDER");
export const GETCARTWOOPRODUCTS = createRequestTypes("GETCARTWOOPRODUCTS");
export const UPDATECARTWOOPRODUCTS = createRequestTypes("UPDATECARTWOOPRODUCTS");
export const REMOVECARTWOOPRODUCT = createRequestTypes("REMOVECARTWOOPRODUCT");
export const CHECKUSERSIGNIN = "CHECKUSERSIGNIN";
export const SETUSER = "SETUSER";
export const REMOVEFROMCART="REMOVEFROMCART";
export const SETORDERFALSE = "SETORDERFALSE";
export const SEARCH = createRequestTypes("SEARCH");

export function setUser(payload) {
  return { type: "SETUSER", payload };
}

export function removeFromCart(){
  return { type: "REMOVEFROMCART"};
}
export function setOrderFalse(){
  return { type: "SETORDERFALSE"};
}
export function checkUserSignin(payload) {
  let userDetails=null;
  let accessToken=null;
  if(payload){
    const GoogleAuth = payload.auth2.getAuthInstance();
    if(GoogleAuth.isSignedIn.get()){
        let userData=GoogleAuth.currentUser.get().getBasicProfile();
        userDetails={
          name:userData.getName(),
          email:userData.getEmail(),
          first_name:userData.getFamilyName(),
          last_name:userData.getGivenName(),
          accessToken:GoogleAuth.currentUser.get().wc.id_token,
          username:userData.getEmail().split("@")[0]
        }
      }
  }
  return { type: "CHECKUSERSIGNIN",userDetails:userDetails };
}

function action(type, payload = {}) {
  return { type, ...payload };
}



// Woocommerce Customer GetCustomer, Get All Customers, Create , Update.

export const getWooCustomer = {
  request: data => action(GETWOOCUSTOMER[REQUEST], { data }),
  success: (data, response) =>action(GETWOOCUSTOMER[SUCCESS], { data, response }),
  failure: (data, error) =>action(GETWOOCUSTOMER[FAILURE], { data, error })
};
export const getWooCustomerList = {
  request: data => action(GETWOOCUSTOMERLIST[REQUEST], { data }),
  success: (data, response) =>action(GETWOOCUSTOMERLIST[SUCCESS], { data, response }),
  failure: (data, error) =>action(GETWOOCUSTOMERLIST[FAILURE], { data, error })
};
export const createWooCustomers = {
  request: data => action(CREATEWOOCUSTOMERS[REQUEST], { data }),
  success: (data, response) =>action(CREATEWOOCUSTOMERS[SUCCESS], { data, response }),
  failure: (data, error) =>action(CREATEWOOCUSTOMERS[FAILURE], { data, error })
};
export const updateWooCustomers = {
  request: data => action(UPDATEWOOCUSTOMERS[REQUEST], { data }),
  success: (data, response) =>action(UPDATEWOOCUSTOMERS[SUCCESS], { data, response }),
  failure: (data, error) =>action(UPDATEWOOCUSTOMERS[FAILURE], { data, error })
};


// Woocommerce Shipping Zones and ShippingMethods

export const getWooShippingZones = {
  request: data => action(GETWOOSHIPPINGZONES[REQUEST], { data }),
  success: (data, response) =>action(GETWOOSHIPPINGZONES[SUCCESS], { data, response }),
  failure: (data, error) =>action(GETWOOSHIPPINGZONES[FAILURE], { data, error })
};
export const getWooShippingMethods = {
  request: data => action(GETWOOSHIPPINGMETHODS[REQUEST], { data }),
  success: (data, response) =>action(GETWOOSHIPPINGMETHODS[SUCCESS], { data, response }),
  failure: (data, error) =>action(GETWOOSHIPPINGMETHODS[FAILURE], { data, error })
};
export const getWooShippingZoneMethods = {
  request: data => action(GETWOOSHIPPINGZONEMETHODS[REQUEST], { data }),
  success: (data, response) =>action(GETWOOSHIPPINGZONEMETHODS[SUCCESS], { data, response }),
  failure: (data, error) =>action(GETWOOSHIPPINGZONEMETHODS[FAILURE], { data, error })
};
// Woo Commerce Products and Categories

export const getWooProducts = {
  request: data => action(WOOPRODUCTS[REQUEST], { data }),
  success: (data, response) =>action(WOOPRODUCTS[SUCCESS], { data, response }),
  failure: (data, error) =>action(WOOPRODUCTS[FAILURE], { data, error })
};

export const getSingleWooProduct = {
  request: data => action(SINGLEWOOPRODUCT[REQUEST], { data }),
  success: (data, response) =>action(SINGLEWOOPRODUCT[SUCCESS], { data, response }),
  failure: (data, error) =>action(SINGLEWOOPRODUCT[FAILURE], { data, error })
};

export const getWooProductsCategories = {
  request: data => action(WOOPRODUCTSCATEGORIES[REQUEST], { data }),
  success: (data, response) =>action(WOOPRODUCTSCATEGORIES[SUCCESS], { data, response }),
  failure: (data, error) =>action(WOOPRODUCTSCATEGORIES[FAILURE], { data, error })
};

// Order userDetails

export const createWooOrder = {
  request: data => action(CREATEWOOORDER[REQUEST], { data }),
  success: (data, response) =>action(CREATEWOOORDER[SUCCESS], { data, response }),
  failure: (data, error) =>action(CREATEWOOORDER[FAILURE], { data, error })
};

export const updateWooOrder = {
  request: data => action(UPDATEWOOORDER[REQUEST], { data }),
  success: (data, response) =>action(UPDATEWOOORDER[SUCCESS], { data, response }),
  failure: (data, error) =>action(UPDATEWOOORDER[FAILURE], { data, error })
};


// Cart Details
export const getCartWooProducts = {
  request: data => action(GETCARTWOOPRODUCTS[REQUEST], { data }),
  success: (data, response) =>
    action(GETCARTWOOPRODUCTS[SUCCESS], { data, response }),
  failure: (data, error) => action(GETCARTWOOPRODUCTS[FAILURE], { data, error })
};

export const updateCartWooProducts = {
  request: data => action(UPDATECARTWOOPRODUCTS[REQUEST], { data }),
  success: (data, response) =>
    action(UPDATECARTWOOPRODUCTS[SUCCESS], { data, response }),
  failure: (data, error) => action(UPDATECARTWOOPRODUCTS[FAILURE], { data, error })
};

export const removeCartWooProduct = {
  request: data => action(REMOVECARTWOOPRODUCT[REQUEST], { data }),
  success: (data, response) =>
    action(REMOVECARTWOOPRODUCT[SUCCESS], { data, response }),
  failure: (data, error) => action(REMOVECARTWOOPRODUCT[FAILURE], { data, error })
};
