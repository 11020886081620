import React, { Component } from "react";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import "../../scss/navbar.scss";
import * as Actions from "../../actions";
import { getCookie, deleteCookie } from "../../services/helpers";
import { setUser } from "../../actions";
import Nav from "react-bootstrap/Nav";

class UserBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      cartDetails:null
    };
  }
  componentDidMount() {
    var c = getCookie("s-atk");
  }

  logout() {
    deleteCookie("s-atk");
    this.props.setUser({ email: null, name: null, photo: null });
  }
  render() {
    let name = null;
    let totalAmount = 0;
    if (this.props.user) {
      name = this.props.user.name;
    }
    if (this.props.wooCartProducts.length) {
      let cartProducts = this.props.wooCartProducts;
      if (cartProducts.length) {
        for (var i = 0; i < cartProducts.length; i++) {
          totalAmount =
            totalAmount + (cartProducts[i].discounted_price ? cartProducts[i].discounted_price : cartProducts[i].price ) * cartProducts[i].quantity;
        }
      }
    }
    totalAmount = Math.round(totalAmount * 100) / 100;

    return (
      <div className="topbar">
        <div className="container">
          <div className="register-block">
            Hi!
            {name ? (
              <h6 className="login-name-block">{name}</h6>
            ) : (
              <LinkContainer to="/login">
                <Nav.Link>Sign in</Nav.Link>
              </LinkContainer>
            )}
          </div>

          <div className="price-block">
            <a href="">
              <i className="fa fa-shopping-bag" aria-hidden="true" />
            </a>{" "}
            <span>Your bag: ${totalAmount}</span>
            {name ? (
              <div className="signout-block">
                <LinkContainer to="">
                  <Nav.Link onClick={this.logout.bind(this)}>Logout</Nav.Link>
                </LinkContainer>
              </div>
            ) : (
              <div className="signout-block" />
            )}
          </div>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.get("user"),
    wooCartProducts:state.get("products").cartWooProducts,
    listAllCustomers: state.get("user").listAllCustomers,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setUser: user => dispatch(setUser(user)),
    getWooCustomer: data =>dispatch(Actions.getWooCustomer.request(data))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBlock);
