import React, { Component } from "react";
import * as Actions from "../../actions";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { BeatLoader } from "react-spinners";
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = { show: "", items: "", adding: false ,maxHeight:""};
    this.myRef = React.createRef();
  }
  componentDidMount() {
  }
  componentWillReceiveProps(props) {
    this.setState({ adding: false });
  }
  addtoCart(product,e) {

    this.props.getCartWooProducts(product);
    this.setState({ show: "show", adding: true });
    setTimeout(() => {
      this.setState({
        show: ""
      });
    }, 1000);
  }

  render() {
    return (
      <div id="item" className="col-md-6 col-lg-3 item" >
        <div className="hot_block" >
          <LinkContainer
            to={
              "/product/" +
              this.props.product.product_id +
              "/" +
              this.props.product.name
            }
          >
            <a>
            {this.props.product.slug ?(<img
              src={
                this.props.product.thumbnail
              }
              alt={
                this.props.product.thumbnail
              }
            />):  (<img
                src={require(`../../images/product_images/${
                  this.props.product.thumbnail
                }`)}
                alt={require(`../../images/product_images/${
                  this.props.product.thumbnail
                }`)}
              />)}
            </a>
          </LinkContainer>
          <LinkContainer
            to={
              "/product/" +
              this.props.product.product_id +
              "/" +
              this.props.product.name
            }
          >
            <a>
              <h3 className="black pt-3">{`${this.props.product.name.substring(0, 35)}...`}</h3>
            </a>
          </LinkContainer>
          <h3 className="red">
            {"$"}
            {this.props.product.discounted_price !== undefined &&
            this.props.product.discounted_price !== "0.00"
              ? this.props.product.discounted_price
              : this.props.product.price}
          </h3>
          <button className="btn btn-sm" onClick={this.addtoCart.bind(this,this.props.product)}>
            Add to cart
          </button>
          <span className={"add_to_cart mt-2"}>
            <h3 className={"addcart" + this.state.show ? this.state.show : ""}>
              {"Adding to cart"}
            </h3>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.get("products").cart,
    token: state.get("user").token,
    wooCartProducts:state.get("products").cartWooProducts,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getCartWooProducts: data => dispatch(Actions.getCartWooProducts.request(data))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product);
