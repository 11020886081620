import React, { Component } from "react";
import { connect } from "react-redux";

import "./scss/App.scss";
import { ConnectedRouter } from "connected-react-router/immutable";
import routes from "./routes";
import * as Actions from "./actions";
import { getCookie } from "./services/helpers";
import { BarLoader } from "react-spinners";
function demoAsyncCall() {
  return new Promise(resolve => setTimeout(() => resolve(), 2500));
}
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { token: "null", loaded: true, loading: true };
    this.handleLoad = this.handleLoad.bind(this);
  }
  componentDidMount() {
    this.props.getWooProducts();
    this.props.getWooProductsCategories();
    demoAsyncCall().then(() => this.setState({ loading: false }));
    var c = getCookie("s-atk");
    setTimeout(()=>{
      if(window.gapi){
        let gapi=window.gapi;
        this.props.checkUserSignin(gapi);
      }
    },5000)
    let cartProducts=[];
    if(localStorage.cartProducts){
      cartProducts=JSON.parse(localStorage.cartProducts);
    }

    if(cartProducts.length>0){
      cartProducts.map((product)=>{
        this.props.getCartWooProducts(product);
      })
    }
    window.addEventListener("load", this.handleLoad);
  }
  handleLoad() {
    this.setState({ loaded: false });
  }
  componentWillReceiveProps(props, b, c) {

    let localCart = JSON.parse(localStorage.getItem("react-shop-cart"));
    if (localCart != null) {

      this.setState({ cart: "" });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <BarLoader
          sizeUnit={"px"}
          size={150}
          color={"#36D7B7"}
          height={4}
          width={window.innerWidth}
          loaded={this.state.loaded}
        >
          {this.handleLoad}
        </BarLoader>
      );
    } else {
      if (
        this.props.wooCategories &&
        this.props.wooCategories.length > 0
      ) {
        let categories=[];
        this.props.wooCategories.forEach((item, i) => {
          categories.push(item);
        });
        return (
          <div className="App">
            <ConnectedRouter
              history={this.props.history}
            >
              {routes}
            </ConnectedRouter>
          </div>
        );
      } else {
        return <div />;
      }
    }
  }
}


const mapStateToProps = state => ({
  cart: state.get("products").cart,
  userSignData: state.get("user").userSigninDetails,
  woo_products:state.get("products").woo_products,
  wooCategories:state.get("products").wooCategories,
});

const mapDispatchToProps = dispatch => ({
  checkUserSignin: data => dispatch(Actions.checkUserSignin(data)),
  getWooProducts: (data) => dispatch(Actions.getWooProducts.request(data)),
  getWooProductsCategories: (data) => dispatch(Actions.getWooProductsCategories.request(data)),
  getCartWooProducts:(data) => dispatch(Actions.getCartWooProducts.request(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
