import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import {Row,Form,Col} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { ButtonToolbar, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../scss/cart.scss";
import * as Actions from "../../actions";
import { connect } from "react-redux";
import axios from "axios";
import AddressPopupForm from "./addressPopupForm";
import {BeatLoader, ClipLoader } from "react-spinners";
class Delivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: "",
      customer: {billing:{}},
      deliveryOption:"",
      errors: {},
      address: {},
      modalShow: false,
      address_id: "",
      default: false
    };
    this.handleClose = this.handleClose.bind(this);
    this.stage = 0;
  }
  componentDidMount() {
    this.props.getWooShippingZones();
    this.props.getWooShippingMethods();
    this.props.getWooCustomer(this.props.user ? this.props.user.email : "raju@teamdevx.com");

  }
  componentWillReceiveProps(props) {
    if (props.user) {
      if (props.user.email) {
        if (props.customer) {
          if (props.customer !== this.state.customer) {
            // this.setState({ customer: props.customer });
          }
        } else {
          // props.getCustomerInfo({
          //   token: this.props.token,
          //   inEmail: props.user.email
          // });
        }
      }
    }
  }
  changeRegion(e) {
    let state = this.state;
    let wooShippingZone=JSON.parse(
      e.currentTarget
        .querySelectorAll("option[value='" + e.currentTarget.value + "']")[0]
        .getAttribute("data-region")
    );
    state["region"] = e.currentTarget.value;
    this.props.getWooShippingZoneMethods({id:wooShippingZone.id});
    state["regionName"] = e.currentTarget.querySelectorAll(
      "option[value='" + e.currentTarget.value + "']"
    )[0].innerText;
    state["completeRegion"] = JSON.parse(
      e.currentTarget
        .querySelectorAll("option[value='" + e.currentTarget.value + "']")[0]
        .getAttribute("data-region")
    );
    this.setState(state);
    this.props.setDelivarydetails(this.state, this.state);
  }
  setShippingOption(e) {
    let state = this.state;
    state["shippingoption"] = JSON.parse(
      e.currentTarget.getAttribute("data-option")
    );
    this.setState(state);
    this.props.setDelivarydetails(this.state, this.state);
  }

  changed(e) {
    let state = this.state;
    state["customer"][e.currentTarget.name] = e.currentTarget.value;
    this.setState(state);
    this.props.setDelivarydetails(this.state, this.state);
  }
  itemInsert(address) {
    var addressDetails = {};
    let state = this.state;
    this.setState({ customer:this.props.wooCustomerDetails,address: address, shippingoption: null });
  }
  handleSelectDeliveryOptions(option) {
    this.setState({deliveryOption: JSON.parse(option.target.value)});
    this.props.setDelivarydetails(JSON.parse(option.target.value));
  }
  handleEdit(address_id) {
    let address=this.props.wooCustomerDetails.billing;
    this.setState({
          address: address,
          modalShow: true,
          addNewAddress: true,
        });
  }
  handleDelete(address_id) {
    confirmAlert({
      title: "Delete Address",
      message: "are you sure delete this address!",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ region: null });
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  }

  handleAdd() {
    this.setState({ modalShow: true, addNewAddress: false, address: {} });
  }
  backStage() {
    this.props.backStage(this.stage);
  }
  nextStage(e) {
    let state = this.state;
    let this_ref = this;
    state["errors"] = {};
    let hasErrors = false;
    if (!state["customer"]["billing"]["address_1"]) {
      state["errors"]["address_1"] = "Name is required";
      hasErrors = true;
    }
    if (!state["customer"]["billing"]["city"]) {
      state["errors"]["city"] = "City is required";
      hasErrors = true;
    }
    if (!state["customer"]["billing"]["postcode"]) {
      state["errors"]["postal_code"] = "Zip code is required";
      hasErrors = true;
    }
    if (!state["customer"]["billing"]["country"]) {
      state["errors"]["country"] = "Country is required";
      hasErrors = true;
    }
    if (!state["region"]) {
      state["errors"]["region"] = "Should select a region";
      hasErrors = true;
    }
    if (!state["deliveryOption"]) {
      state["errors"]["deliveryOption"] = "Should select a delivery option";
      hasErrors = true;
    }
    if (!hasErrors) {
      this_ref.props.nextStage(this_ref.stage);
    }
    this.setState(state);
  }
  showError(opt) {
    if (this.state["errors"][opt]) {
      return (
        <div className="alert alert-danger">{this.state["errors"][opt]}</div>
      );
    } else {
      return <span />;
    }
  }

  handleClose() {
    this.setState({ modalShow: false, address: {} });
  }

  setDefaultAddress(address) {
    this.setState({
      default: true,
      address_id: address.id,
      region: address.shipping_region_id
    });
  }
  addressList(getAddress) {
    let address=getAddress;
      return (
        <div className="col-md-6 col-lg-6 item">
          <div
            className={
              "hot_block" +
              " " +
              (address.id == this.state.address_id ? "default" : "")
            }
            onClick={() => this.setDefaultAddress(address)}
          >
            <div
              className="address"
              onClick={add => this.itemInsert(address)}
            >
              <h3>{address.company} Address</h3>
              <div>
                <div>{address.address_1} </div>
                <div> {address.address_2}</div>
                <div>
                  {address.city}, {address.city}, {address.postcode}
                </div>
                <div> {address.country}</div>
              </div>
              <p>Mobile number : {address.phone} </p>
            </div>

            <div className="next_step">
              <button
                type="button"
                className="btn btn-md save_address"
                onClick={() => this.handleEdit(address.id)}
              >
                Edit
              </button>

              <button
                type="button"
                className="btn btn-md "
                onClick={() => this.handleDelete(address.id)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      );
  }
  render() {
    let this_ref = this;
    let wooShippingZoneMethods=this.props.wooShippingZoneMethods;
    let deliveryOptions=wooShippingZoneMethods[0]? wooShippingZoneMethods[0].settings:"";
    let shippingOptions = [];
    if(wooShippingZoneMethods[0] && deliveryOptions != "" ){
      Object.values(deliveryOptions).map((option,index)=>{
        if(this.state.completeRegion && this.state.completeRegion.name == "India"){
          if(option.id == "class_cost_53" || option.id == "class_cost_54" || option.id == "class_cost_55"){
            shippingOptions.push(option);
          }
        }else {
          if(option.id == "class_cost_56" || option.id == "class_cost_57"){
            shippingOptions.push(option);
          }
        }
      })
    }
    let customer = {  address1: "",  address2: "", city: "", zip: "",  country: ""};
    if (this.state.customer) {
      if (this.state.customer.billing.address_1) {
        customer = this.state.customer.billing;
      }
    }
    let getAddress =this.props.wooCustomerDetails ? this.props.wooCustomerDetails.billing : "";
    let wooShippingZones=this.props.wooShippingZones;
    let wooShippingMethods=this.props.wooShippingMethods;
    return (
      <React.Fragment>
      {getAddress ?
        (<Container>
          <Row className="address_block items_block">
            <AddressPopupForm
              modalShow={this.state.modalShow}
              onHide={this.handleClose}
              customerid={this.props.wooCustomerDetails.id}
              addressDetails={this.props.wooCustomerDetails.billing}
              customerDetails={this.state.customer}
              setDelivarydetails={this.props.setDelivarydetails}
              addNewAddress={this.state.addNewAddress}
              region={this.state.region ? this.state.region : "hello"}
              setDefaultAddress={address => this.setDefaultAddress(address)}
            />

            <div className="col-md-12">
              <h2 className="pb-3">Select Address</h2>
            </div>
            { getAddress ? this.addressList(getAddress) : (<div className="col-md-6 col-lg-4 item add-address-block">
              <div className="hot_block" onClick={() => this.handleAdd()}>
                <div className="address">
                  <i className="fas fa-plus" />
                  <h3>Add Address</h3>
                </div>
              </div>
            </div>) }
          </Row>
          <Row >
          <div className="col-md-6">
              <label htmlFor="male">
                <h3>Region *</h3>
              </label>
              <select
                className="woo_region selectpicker"
                onChange={this_ref.changeRegion.bind(this_ref)}
              >
                {this.props.wooShippingZones.map(function(region, index) {
                    if(index==0){
                    return (<option
                    key={index}
                    id={region.id}
                    data-region={JSON.stringify(region)}
                    value={"Please Select"}
                  >
                    {"Please Select"}
                  </option>);
                }
                  if(index!=0){
                    return (
                      <option
                        key={index}
                        id={region.id}
                        data-region={JSON.stringify(region)}
                        value={region.name}
                      >
                        {region.name}
                      </option>
                    );
                  }
                })}
              </select>
              {this.showError("region")}
          </div>
          <div className="col-md-6">
            <label htmlFor="male">
              <h3>{wooShippingZoneMethods.length > 0  && wooShippingZoneMethods[0].method_title}</h3>
            </label>
            <div dangerouslySetInnerHTML={{ __html: (wooShippingZoneMethods.length >0 ? wooShippingZoneMethods[0].method_description:"") }} />
          </div>
          {shippingOptions[0] ? (<div className="col-md-12">
              <label htmlFor="male">
                <h3>Delivery Options *</h3>
              </label>
                <div>
                { shippingOptions.map((item,index)=>{
                  return (<Form.Check inline
                    type="radio"
                    label={item.label.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "")+"($"+item.value+")"}
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                    value={JSON.stringify(item)}
                    onChange={this.handleSelectDeliveryOptions.bind(this)}
                  />)
                })}
              </div>
              {this.showError("deliveryOption")}
              </div>) :" "}
          </Row>
        </Container> ) : (<div className="clip_loader">
          <ClipLoader
            sizeUnit={"px"}
            size={80}
            color={"#f62f5e"}
            loading={this.state.loading}
          />
        </div>)}
        <div className="col-md-12">
          <div className="checkout_next">
            <button
              onClick={this.backStage.bind(this)}
              type="button"
              className="btn btn-md btn-white back"
            >
              Back
            </button>
            <div className="next_step">
              <button
                onClick={this.nextStage.bind(this)}
                type="button"
                className="btn btn-md "
              >
                Next Step
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    customer: state.get("user").customer,
    token: state.get("user").token,
    WooCustomerList:state.get("user").listAllCustomers,
    wooCustomerDetails:state.get("user").wooCustomerDetails,
    wooShippingZones: state.get("shipping").wooShippingZones,
    wooShippingMethods: state.get("shipping").wooShippingMethods,
    wooShippingZoneMethods: state.get("shipping").wooShippingZoneMethods,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getWooCustomerList:()=>dispatch(Actions.getWooCustomerList.request()),
    getWooCustomer: data => dispatch(Actions.getWooCustomer.request(data)),
    updateWooCustomers:data =>dispatch(Actions.updateWooCustomers(data)),
    getWooShippingZones: data =>dispatch(Actions.getWooShippingZones.request(data)),
    getWooShippingMethods: data =>dispatch(Actions.getWooShippingMethods.request(data)),
    getWooShippingZoneMethods: data =>dispatch(Actions.getWooShippingZoneMethods.request(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Delivery);
