import * as ActionTypes from "../actions";

const intialState = {
  loading: false,
  ordersuccess:false,
  wooOrders:[]
};

export default (state = intialState, action) => {
  switch (action.type) {
      case ActionTypes.SETORDERFALSE:
        return {
          ...state,
          ordersuccess:false,
        };
      case ActionTypes.CREATEWOOORDER.REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case ActionTypes.CREATEWOOORDER.SUCCESS:
        return {
          ...state,
          isLoading: false,
          ordersuccess:true,
          wooOrders: action.response
        };
      case ActionTypes.CREATEWOOORDER.FAILURE:
        return {
          ...state,
          isLoading: false,
          ordersuccess:false,
          error: action.errors
        };
        case ActionTypes.UPDATEWOOORDER.REQUEST:
          return {
            ...state,
            isLoading: true,
          };
        case ActionTypes.UPDATEWOOORDER.SUCCESS:
          return {
            ...state,
            isLoading: false,
            wooOrders: action.response
          };
        case ActionTypes.UPDATEWOOORDER.FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.errors
          };
    default:
      return state;
  }
};
