import { takeLatest, takeEvery, put, call, fork } from "redux-saga/effects";
import { api } from "../services";
import * as actions from "../actions";

const {
  createWooCustomers,
  updateWooCustomers,
  getWooCustomerList,
  getWooCustomer,
  getSingleWooProduct,
  getWooProducts,
  getWooProductsCategories,
  getWooShippingZones,
  getWooShippingMethods,
  getWooShippingZoneMethods,
  createWooOrder,
  updateWooOrder,
} = actions;

//reusable fetch subroutine.
function* fetchEntity(entity, apiFn, id, url) {
  const { response, error } = yield call(apiFn, url || id);
  if (response) {
    yield put(entity.success(id, response));
  } else {
    yield put(entity.failure(id, error));
  }
}

export const createWooCustomer = fetchEntity.bind(null, createWooCustomers, api.addWooCustomers);
export const updateWooCustomer = fetchEntity.bind(null, updateWooCustomers, api.updateWooCustomers);
export const fetchWooCustomers = fetchEntity.bind(null, getWooCustomerList, api.getWooCustomerList);
export const fetchWooCustomer = fetchEntity.bind(null, getWooCustomer, api.getWooCustomer);
export const fetchWooShippingZones = fetchEntity.bind(null, getWooShippingZones, api.getWooShippingZones);
export const fetchWooShippingMethods = fetchEntity.bind(null, getWooShippingMethods, api.getWooShippingMethods);
export const fetchWooShippingZoneMethods = fetchEntity.bind(null, getWooShippingZoneMethods, api.getWooShippingZoneMethods);
export const fetchSingleProduct = fetchEntity.bind(null, getSingleWooProduct, api.getSingleWooProduct);
export const fetchWooProducts = fetchEntity.bind(null, getWooProducts, api.getWooProducts);
export const fetchWooProductsCategories = fetchEntity.bind(null, getWooProductsCategories, api.getWooProductsCategories);
export const fetchcreateWooOrder = fetchEntity.bind(null, createWooOrder, api.createOrder);
export const fetchupdateWooOrder = fetchEntity.bind(null, updateWooOrder, api.updateOrder);

function* loadCreateCustomerInfo(action) {
  yield call(createWooCustomer, action.data);
}

function* loadUpdateCustomerInfo(action) {
  yield call(updateWooCustomer, action.data);
}

function* loadSingleProduct(action) {
  yield call(fetchSingleProduct, action.data);
}

function* loadcreateWooOrder(action) {
  yield call(fetchcreateWooOrder, action.data);
}

function* loadupdateWooOrder(action) {
  yield call(fetchupdateWooOrder, action.data);
}

function* loadWooCustomers(action) {
  yield call(fetchWooCustomers, action.data);
}

function* loadWooCustomer(action) {
  yield call(fetchWooCustomer, action.data);
}
function* loadWooProducts(action) {
  yield call(fetchWooProducts, action.data);
}
function* loadWooProductsCategories(action) {
  yield call(fetchWooProductsCategories, action.data);
}

function* loadWooShippingZones(action) {
  yield call(fetchWooShippingZones, action.data);
}
function* loadWooShippingMethods(action) {
  yield call(fetchWooShippingMethods, action.data);
}

function* loadWooShippingZoneMethods(action) {
  yield call(fetchWooShippingZoneMethods, action.data);
}


//+++++++++++++++++//


function* watchLoadCreateCustomers() {
  yield takeLatest(actions.CREATEWOOCUSTOMERS.REQUEST, loadCreateCustomerInfo);
}

function* watchLoadUpdateCustomers() {
  yield takeLatest(actions.UPDATEWOOCUSTOMERS.REQUEST, loadUpdateCustomerInfo);
}

function* watchLoadSingleProduct() {
  yield takeLatest(actions.SINGLEWOOPRODUCT.REQUEST, loadSingleProduct);
}

function* watchLoadWooCustomers() {
  yield takeLatest(actions.GETWOOCUSTOMERLIST.REQUEST, loadWooCustomers);
}

function* watchLoadWooCustomer() {
  yield takeLatest(actions.GETWOOCUSTOMER.REQUEST, loadWooCustomer);
}

function* watchLoadWooProducts() {
  yield takeLatest(actions.WOOPRODUCTS.REQUEST, loadWooProducts);
}

function* watchLoadWooShippingZones() {
  yield takeLatest(actions.GETWOOSHIPPINGZONES.REQUEST, loadWooShippingZones);
}

function* watchLoadWooShippingMethods() {
  yield takeLatest(actions.GETWOOSHIPPINGMETHODS.REQUEST, loadWooShippingMethods);
}

function* watchLoadWooShippingZoneMethods() {
  yield takeLatest(actions.GETWOOSHIPPINGZONEMETHODS.REQUEST, loadWooShippingZoneMethods);
}

function* watchLoadWooProductsCategories() {
  yield takeLatest(actions.WOOPRODUCTSCATEGORIES.REQUEST, loadWooProductsCategories);
}

function* watchLoadcreateWooOrder() {
  yield takeLatest(actions.CREATEWOOORDER.REQUEST, loadcreateWooOrder);
}

function* watchLoadupdateWooOrder() {
  yield takeLatest(actions.UPDATEWOOORDER.REQUEST, loadupdateWooOrder);
}



export default function*() {
  yield fork(watchLoadCreateCustomers);
  yield fork(watchLoadUpdateCustomers);
  yield fork(watchLoadSingleProduct);
  yield fork(watchLoadWooCustomers);
  yield fork(watchLoadWooCustomer);
  yield fork(watchLoadWooProducts);
  yield fork(watchLoadWooProductsCategories);
  yield fork(watchLoadWooShippingZones);
  yield fork(watchLoadWooShippingMethods);
  yield fork(watchLoadWooShippingZoneMethods);
  yield fork(watchLoadcreateWooOrder);
  yield fork(watchLoadupdateWooOrder);
}
