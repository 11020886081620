import * as ActionTypes from "../actions";
//import { store } from "../index";

const intialState = {
  loading: false,
  products: [],
  single_product:[],
  woo_products: [],
  cartWooProducts:[],
  wooCategories:[],
  product: [],
  productrecommendations: [],
  productLocations: [],
  addAddress: [],
  address: {},
  cart:[],
};

function getCategoryName(categories, id) {
  let categoriesdata = categories.filter(
    category => category.department_id === id
  );
  return categoriesdata[0] ? categoriesdata[0].name : "";
}
function getSubCategoryName(subcategories, id) {
  return subcategories.filter(category => category.category_id === id)[0].name;
}
export default (state = intialState, action) => {
  let cart=null;
  switch (action.type) {

      case ActionTypes.SINGLEWOOPRODUCT.REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ActionTypes.SINGLEWOOPRODUCT.SUCCESS:
        return {
          ...state,
          isLoading: false,
          single_product: action.response
        };
      case ActionTypes.SINGLEWOOPRODUCT.FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.errors
        };

      case ActionTypes.WOOPRODUCTS.REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ActionTypes.WOOPRODUCTS.SUCCESS:
        return {
          ...state,
          isLoading: false,
          woo_products: action.response
        };
      case ActionTypes.WOOPRODUCTS.FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.errors
        };

        case ActionTypes.WOOPRODUCTSCATEGORIES.REQUEST:
          return {
            ...state,
            isLoading: true
          };
        case ActionTypes.WOOPRODUCTSCATEGORIES.SUCCESS:
          return {
            ...state,
            isLoading: false,
            wooCategories: action.response
          };
        case ActionTypes.WOOPRODUCTSCATEGORIES.FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.errors
          };
        case ActionTypes.REMOVEFROMCART:
          cart = [];
          localStorage.removeItem("cartProducts");
          return {
            ...state,
            isLoading: false,
            cartWooProducts: cart
        };
        case ActionTypes.GETCARTWOOPRODUCTS.REQUEST:
          let getIndex=state.cartWooProducts.findIndex(elm=>elm.product_id===action.data.product_id);
          let cartvalue=null;
          if(getIndex != -1){
            action.data.quantity=(action.data.quantity ? (action.data.quantity+1) :1);
            state.cartWooProducts.splice(getIndex, 1, action.data);
            cartvalue=[...state.cartWooProducts];
          }else{
            action.data.quantity=1;
            cartvalue=[...state.cartWooProducts,action.data];
          }
          localStorage.setItem("cartProducts", JSON.stringify(state.cartWooProducts));
          return {
            ...state,
            isLoading: true,
            cartWooProducts: cartvalue
        };
        case ActionTypes.GETCARTWOOPRODUCTS.SUCCESS:
          return {
            ...state,
            isLoading: false,
            cartWooProducts: [...action.data]
        };
        case ActionTypes.GETCARTWOOPRODUCTS.FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.errors
          };
        case ActionTypes.UPDATECARTWOOPRODUCTS.REQUEST:
          let productIndex=state.cartWooProducts.findIndex(elm=>elm.product_id===parseInt(action.data.inProductId));
          let updatecart=null;
          if(getIndex != -1){
            state.cartWooProducts[productIndex].quantity=(parseInt(action.data.inQuantity) ? parseInt(action.data.inQuantity) :1);
            // state.cartWooProducts.splice(productIndex, 1, state.cartWooProducts[productIndex]);
            updatecart=[...state.cartWooProducts];
          }else{
            updatecart=[...state.cartWooProducts];
          }
            return {
              ...state,
              isLoading: true,
              cartWooProducts: updatecart
            };
        case ActionTypes.UPDATECARTWOOPRODUCTS.SUCCESS:
            return {
              ...state,
              isLoading: false,
              cartWooProducts: [...action.data]
          };
        case ActionTypes.UPDATECARTWOOPRODUCTS.FAILURE:
            return {
              ...state,
              isLoading: false,
              error: action.errors
            };
        case ActionTypes.REMOVECARTWOOPRODUCT.REQUEST:
            let index=state.cartWooProducts.findIndex(elm=>{
              return (elm.product_id===parseInt(action.data.inProductId))
            });
            let removeProduct=null;
            if(index != -1){
              state.cartWooProducts.splice(index, 1);
              removeProduct=[...state.cartWooProducts];
            }else{
              action.data.quantity=1;
              removeProduct=[...state.cartWooProducts];
            }
            return {
                ...state,
                isLoading: true,
                cartWooProducts: removeProduct
              };
        case ActionTypes.REMOVECARTWOOPRODUCT.SUCCESS:
            return {
              ...state,
              isLoading: false,
              cartWooProducts: [...state.cartWooProducts]
        };
        case ActionTypes.REMOVECARTWOOPRODUCT.FAILURE:
            return {
              ...state,
              isLoading: false,
              error: action.errors
        };
    default:
      return state;
  }
};
