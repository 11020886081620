import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import "../../scss/cart.scss";
import "../../scss/checkout.scss";
import * as Actions from "../../actions";
import { setRegion, setShippingOption } from "../../actions";
import { connect } from "react-redux";
class AddressPopupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: "",
      editregion: "",
      customer: {},
      errors: {},
      address: "",
      add_address: "",
      modalShow: false
    };
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {
    this.props.getWooShippingZones();
    this.props.getWooShippingMethods();
  }
  componentWillReceiveProps(props) {
    if (this.props.addressDetails) {
      this.setState({
        address: this.props.addressDetails
      });
    }
  }
  changeRegion(e) {
    let state = this.state;
    state["address"]["shipping_region_id"] = e.currentTarget.value;
    state["address"]["region"] = e.currentTarget.querySelectorAll(
      "option[value='" + e.currentTarget.value + "']"
    )[0].innerText;
    state["completeRegion"] = JSON.parse(
      e.currentTarget
        .querySelectorAll("option[value='" + e.currentTarget.value + "']")[0]
        .getAttribute("data-region")
    );
    this.setState(state);
  }
  setShippingOption(e) {
    let state = this.state;
    state["shippingoption"] = JSON.parse(
      e.currentTarget.getAttribute("data-option")
    );

    this.setState(state);
    this.props.setDelivarydetails(this.state, this.state);
    this.props.setShippingOption(state["shippingoption"]);
  }

  changed(e) {
    let state = this.state;
    state["address"][e.currentTarget.name] = e.currentTarget.value;
    this.setState(state);
    this.props.setDelivarydetails(this.state, this.state);
  }

  showError(opt) {
    if (this.state["errors"][opt]) {
      return (
        <div className="alert alert-danger">{this.state["errors"][opt]}</div>
      );
    } else {
      return <span />;
    }
  }
  setOptions(region) {
    this.props.getShippingOptions({
      token: this.props.token,
      inShippingRegionId: region
    });
  }

  updateAddress() {
    let state = this.state;
    let data={
      billing: {
        company: state["address"]["company"],
        address_1: state["address"]["address_1"],
        address_2: state["address"]["address_2"],
        city: state["address"]["city"],
        state: state["address"]["state"],
        postcode: state["address"]["postcode"],
        country: state["address"]["country"],
        phone: state["address"]["phone"]
      },
    }
    this.props.updateWooCustomers({id:this.props.customerid, data});
    this.props.onHide();
    this.setState({ address: {} });
  }
  saveAddress() {
    let state = this.state;
    let data={
      billing: {
        company: state["address"]["company"],
        address_1: state["address"]["address_1"],
        address_2: state["address"]["address_2"],
        city: state["address"]["city"],
        state: state["address"]["state"],
        postcode: state["address"]["postcode"],
        country: state["address"]["country"],
        phone: state["address"]["phone"]
      },
    }
    this.props.updateWooCustomers({id:this.props.customerid, data});
    this.props.onHide();
    this.setState({ address: {} });
  }
  handleClose() {
    this.setState({ modalShow: false });
  }

  render() {
    let this_ref = this;
    let regions = [];
    let shippingOptions = [];

    if (this.props.regions) regions = this.props.regions;
    if (this.props.shippingOptions)
      shippingOptions = this.props.shippingOptions;
    let addressList = {
      address1: "",
      address2: "",
      city: "",
      zip: "",
      country: ""
    };
    let addAddressList = this.state.address;
    addressList = this.state.address ? this.state.address : "";

    let getAddress = "";
    return (
      <React.Fragment>
        <Modal
          show={this.props.modalShow}
          onHide={this.props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={"edit-address-popup"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.props.addNewAddress ? "Edit Address" : "Add Address"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="">Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={
                        this.props.addNewAddress
                          ? addressList.company
                          : addAddressList.company
                      }
                      name="company"
                      onChange={this.changed.bind(this)}
                    />
                  </div>
                  {this.showError("company")}
                </div>
                <div className="col-md-6" />
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="">Address 1*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={
                        this.props.addNewAddress
                          ? addressList.address_1
                          : addAddressList.address_1
                      }
                      name="address_1"
                      onChange={this.changed.bind(this)}
                    />
                  </div>
                  {this.showError("address_1")}
                  <div className="form-group">
                    <label className="">City *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={addressList.city}
                      name="city"
                      onChange={this.changed.bind(this)}
                    />
                  </div>
                  {this.showError("city")}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="">Address 2</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={
                        this.props.addNewAddress
                          ? addressList.address_2
                          : addAddressList.address_2
                      }
                      name="address_2"
                      onChange={this.changed.bind(this)}
                    />
                  </div>
                  {this.showError("address_2")}

                  <div className="form-group">
                    <label className="">Zip-code *</label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={
                        this.props.addNewAddress
                          ? addressList.postcode
                          : addAddressList.postcode
                      }
                      name="postcode"
                      onChange={this.changed.bind(this)}
                    />
                  </div>
                  {this.showError("postal_code")}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{"Country:"}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={
                        this.props.addNewAddress
                          ? addressList.country
                          : addAddressList.country
                      }
                      name="country"
                      onChange={this.changed.bind(this)}
                    />
                  </div>
                  {this.showError("country")}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{"State:"}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={
                        this.props.addNewAddress
                          ? addressList.state
                          : addAddressList.state
                      }
                      name="state"
                      onChange={this.changed.bind(this)}
                    />
                  </div>
                  {this.showError("state")}

                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{"Mobile Number:"}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={
                        this.props.addNewAddress
                          ? addressList.phone
                          : addAddressList.phone
                      }
                      name="phone"
                      onChange={this.changed.bind(this)}
                    />
                  </div>
                  {this.showError("phone")}

                </div>

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-md save_address"
              onClick={this.props.onHide}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-md save_address"
              onClick={
                this.props.addNewAddress
                  ? this.updateAddress.bind(this)
                  : this.saveAddress.bind(this)
              }
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    wooShippingZones: state.get("shipping").wooShippingZones,
    wooShippingMethods: state.get("shipping").wooShippingMethods,
    user: state.get("user"),
    customer: state.get("user").customer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getWooShippingZones: data =>
      dispatch(Actions.getWooShippingZones.request(data)),
    getWooShippingMethods: data =>
      dispatch(Actions.getWooShippingMethods.request(data)),
    updateWooCustomers: data =>
      dispatch(Actions.updateWooCustomers.request(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressPopupForm);
