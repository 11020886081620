import { WOO_API_ROOT ,WOO_API_AUTH } from "./constants";


function callAPIWOO(endpoint, headers, body, schema) {
  const fullURL =
    endpoint.indexOf(WOO_API_ROOT) === -1 ? WOO_API_ROOT + endpoint : endpoint;
  const headersAuth={}
  return fetch(fullURL, { headers, body, method: body ? "POST" : "GET" }).then(
    response =>
      response
        .json()
        .then(json => ({ json, response }))
        .then(({ json, response }) => {
          if (!response.ok) {
            return Promise.reject(json);
          }
          return json;
        })
        .then(
          response => ({ response }),
          error => ({ error: error.message || "Somehing gone wrong" })
        )
  );
}

function getParams(data) {
  return Object.keys(data)
    .map(key => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    })
    .join("&");
}

export const AddToCart = function(data) {
  return data;
};

// wooCommerce Api calls

export const addWooCustomers = function(data) {
  return callAPIWOO(
    `customers/`,
    {
      "Content-Type": "application/json",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    },
    JSON.stringify(data)
    );

};

export const updateWooCustomers = function(data) {
  console.log("updateWooCustomers",data);
  let updatedData=data.data;
  return callAPIWOO(
    `customers/`+data.id,
    {
      "Content-Type": "application/json",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    },
    JSON.stringify(updatedData)
    );

};

export const getWooCustomer = async (data) => {
  var id=null;
  let userId=null;
  let response = await callAPIWOO(
 `customers/`,
 {
   "Content-Type": "application/x-www-form-urlencoded",
   Accept: "application/json; charset=utf-8",
   Authorization: `Basic ${WOO_API_AUTH}`
 }
 );
 if(response){
   let obj=response.response;
   let customerid=obj.filter((customer) => { return (customer.email == data)});
   id=customerid[0];
   if(customerid){
     if(id){
       userId=id.id
     }
     let woo= await callAPIWOO(
       `customers/`+userId,
       {
         "Content-Type": "application/x-www-form-urlencoded",
         Accept: "application/json; charset=utf-8",
         Authorization: `Basic ${WOO_API_AUTH}`
       }
       );
    return woo;
   }
 }
};

export const getWooCustomerList = function() {
  return callAPIWOO(
    `customers/`,
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    }
    );

};

export const getWooProducts = function(data) {
  return callAPIWOO(
    `products/?per_page=100`,
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    }
    );
};

export const getWooProductsCategories = function(data) {
  return callAPIWOO(
    `products/categories`,
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    }
    );
};

export const getSingleWooProduct = function(data) {
  return callAPIWOO(
    `products/`+data.product_id,
     {
       "Content-Type": "application/x-www-form-urlencoded",
       Accept: "application/json; charset=utf-8",
       Authorization: `Basic ${WOO_API_AUTH}`
     }
    );
};

export const getOrders = function(data) {
  return callAPIWOO(
    `orders/`,
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    }
  );
};

export const createOrder = function(data) {
  return callAPIWOO(
    `orders/`,
    {
      "Content-Type": "application/json",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    },
    JSON.stringify(data)
  );
};

export const updateOrder = function(updatedData) {
  return callAPIWOO(
    `orders/`+updatedData.id,
    {
      "Content-Type": "application/json",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    },
    JSON.stringify(updatedData.data)
  );
};

export const deleteOrder = function(data) {
  return callAPIWOO(
    `orders/`+data.product_id,
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    },
    getParams({ force: data.true })
  );
};

// shipping

export const getWooShippingZones = function(data) {
  return callAPIWOO(
    `shipping/zones/`,
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    }
  );
};

export const getWooShippingZoneMethods = function(data) {
  return callAPIWOO(
    `shipping/zones/`+data.id+`/methods`,
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    }
  );
};

export const getWooShippingMethods = function(data) {
  return callAPIWOO(
    `shipping_methods/`,
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json; charset=utf-8",
      Authorization: `Basic ${WOO_API_AUTH}`
    }
  );
};
