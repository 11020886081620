import * as Actions from "../actions";

const initialState = {
  isLoading: false,
  customer:{},
  updateCustomer:{},
  listAllCustomers:{},
  userSigninDetails:{},
  wooCustomerDetails:{}
};

export default function(state = initialState, action) {
  switch (action.type) {

    case Actions.CREATEWOOCUSTOMERS.REQUEST:
        return {
          ...state,
          isLoading: true
        };
    case Actions.CREATEWOOCUSTOMERS.SUCCESS:
        return {
          ...state,
          isLoading: false,
          ...action.response.customer
        };
    case Actions.CREATEWOOCUSTOMERS.FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error
        };
    case Actions.UPDATEWOOCUSTOMERS.REQUEST:
            return {
              ...state,
              isLoading: true
            };
    case Actions.UPDATEWOOCUSTOMERS.SUCCESS:
            return {
              ...state,
              isLoading: false,
              ...action.response.updateCustomer
            };
    case Actions.UPDATEWOOCUSTOMERS.FAILURE:
            return {
              ...state,
              isLoading: false,
              error: action.error
            };
      case Actions.GETWOOCUSTOMERLIST.REQUEST:
          return {
            ...state,
            isLoading: true,
            listAllCustomers:action.response
          };
      case Actions.GETWOOCUSTOMERLIST.SUCCESS:
          return {
            ...state,
            isLoading: false,
            listAllCustomers:action.response
          };
      case Actions.GETWOOCUSTOMERLIST.FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.error
          };
      case Actions.GETWOOCUSTOMER.REQUEST:
              return {
                ...state,
                isLoading: true,
              };
      case Actions.GETWOOCUSTOMER.SUCCESS:
              return {
                ...state,
                isLoading: false,
                wooCustomerDetails:action.response
              };
      case Actions.GETWOOCUSTOMER.FAILURE:
              return {
                ...state,
                isLoading: false,
                error: action.error
              };
      case Actions.CHECKUSERSIGNIN:
          return {
            ...state,
            isLoading: false,
            userSigninDetails: action.userDetails
          };
      case Actions.SETUSER:
        return {
          ...state,
          isLoading: false,
          ...action.payload
        };
    default:
      return state;
  }
}
