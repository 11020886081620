import React, { Component } from "react";
import "../../scss/productdetails.scss";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import * as Actions from "../../actions";
import "react-confirm-alert/src/react-confirm-alert.css";
import AliceCarousel from "react-alice-carousel";
import { BeatLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";
const handleOnDragStart = e => e.preventDefault();

class WooProductDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: "",
      buttonStyles: { cursor: "pointer" },
      cart: null,
      productImageName: "",
      activeClass: "active",
      adding: false,
      link: null
    };
  }

  addtoCart(product,e) {
    let productDetails={
      product_id:product.id,
      categories:product.categories,
      description:product.description,
      discounted_price:product.discounted_price,
      name:product.name,
      price:product.price,
      quantity:1,
      slug:product.slug,
      thumbnail:product.images[0].src
    }

    this.props.getCartWooProducts(productDetails);

    this.setState({ show: "show", adding: true });
    setTimeout(() => {
      this.setState({
        show: ""
      });
    }, 1000);
  }
  componentDidMount() {
    this.props.getSingleWooProduct({
      token: this.props.token,
      product_id: this.props.match.params.productid
    });
    // this.props.getProductRecommendations({
    //   token: this.props.token,
    //   inProductId: this.props.match.params.productid
    // });
    // this.props.getProductLocations({
    //   token: this.props.token,
    //   inProductId: this.props.match.params.productid
    // });
    // this.props.setSubCategory(false);

    this.setState({ link: this.props.match.params.productid  });
  }

  componentWillReceiveProps(props) {

    let localCart = JSON.parse(localStorage.getItem("react-shop-cart"));
    if (localCart != null) {
      if (this.state.cart === null || this.state.cart === undefined) {
      //   this.props.getCartProducts({
      //     token: props.token,
      //     inCartId: localCart.inCartId
      //   });
      // } else if (props.cart.count !== this.state.cart.count) {
      //   this.props.getCartProducts({
      //     token: props.token,
      //     inCartId: props.cart.inCartId
      //   });
      }

      this.setState({ cart: "", adding: false });
    }

    if (this.state.link !== props.match.params.productid) {
      // props.loadProduct({
      //   token: props.token,
      //   inProductId: props.match.params.productid
      // });
      // props.getProductRecommendations({
      //   token: props.token,
      //   inProductId: props.match.params.productid
      // });
      // props.getProductLocations({
      //   token: props.token,
      //   inProductId: props.match.params.productid
      // });
      this.setState({ link: props.match.params.productid });
    }
  }

  // update(e) {
  //   let state = this.state;
  //   state["buttonStyles"] = { pointerEvents: "none" };
  //   this.setState(state);
  //   let count = parseInt(e.currentTarget.getAttribute("data-quantity"));
  //   let param = parseInt(e.currentTarget.getAttribute("data-param"));
  //   count = count + param;
  //   if (count < 0) {
  //     let state = this.state;
  //     state["buttonStyles"] = { pointerEvents: "auto", cursor: "pointer" };
  //     this.setState(state);
  //   } else {
  //     this.props.updateProductQuantity({
  //       token: this.props.token,
  //       inItemId: e.currentTarget.getAttribute("data-item"),
  //       inQuantity: count
  //     });
  //   }
  // }
  handleClick(name) {
    this.setState({ productImageName: name, activeClass: "" });
  }

  productPrice(product) {
    let discountedPrice = product
      ? product.sale_price
      : "";
    if (discountedPrice !== "0.00") {
      return (
        <div>
          <div className="amount-block pt-3">
            <h6 className="pricetag"> Price:</h6>
            {product
              ? "$" + product.regular_price
              : ""}
            {product ? <p> {""}</p> : ""}
          </div>
          <div className="amount-block pt-3 pb-3">
            <h6 className="pricetag"> Discounted Price:</h6>
            {product
              ? "$" + product.sale_price
              : ""}
          </div>
        </div>
      );
    } else {
      return (
        <div className="amount-block pt-3 mb_price">
          <h6 className="pricetag"> Price:</h6>
          {product
            ? "$" + product.regular_price
            : ""}
        </div>
      );
    }
  }
  render() {
    let product=[];


    if(this.props.woo_products.length){
      let allProducts=this.props.woo_products;
      product = allProducts.filter(product => product.id == (this.props.match.params.productid ? this.props.match.params.productid :""))[0];
    }
    let productImg = product.images[0]
      ? product.images[0].src
      : "";
    let productImg1 = product.images[1]
      ? product.images[1].src
      : "";
    let productImg2 = product.images[2]
      ? product.images[2].src
      : "";
      let productId = product
        ? product.id
        : "";
    let cart = { count: 0, products: [] };
    if (this.props.cart) cart = this.props.cart;
    let hasItems = cart.count > 0 ? true : false;
    let this_ref = this;
    let items = "";
    if (productId.toString() === (this.props.match.params.productid ? this.props.match.params.productid :"")) {
      return (
        <React.Fragment>
          <div id="main" className="mt-5 mb-5">
            <div className="container pt-5">
              <div className="py-3"><LinkContainer to={'/categories/'+(product? product.categories[0].slug:"")}><a>{product? "<< Back":""}</a></LinkContainer></div>
              <div className="product-block bg-white">
                <div className="row">

                  <div className="col-md-6 pt-5 item-left-block">
                    <div className="top-image text-center">
                      <img
                        src={this.state.productImageName ? this.state.productImageName :(product?product.images[0].src
                              : "")
                        }
                        alt="Image"
                        title="Image"
                      />
                    </div>
                    <div className="colors-selection-block">
                      <ul className="list-unstyled">
                      <li
                        className={
                          this.state.productImageName === productImg
                            ? "active"
                            : this.state.activeClass
                        }
                      >
                        <a
                          onClick={() => {
                            this.handleClick(
                              product?product.images[0].src
                                    : ""
                            );
                          }}
                        >
                          <img
                            src={  product?product.images[0].src
                                    : ""  }
                            alt="Image"
                            title="Image1"
                          />
                        </a>
                      </li>
                        <li
                          className={
                            this.state.productImageName === productImg1
                              ? "active"
                              : " "
                          }
                        >
                          <a
                            onClick={() => {
                              this.handleClick(
                                product?product.images[1].src
                                      : ""
                              );
                            }}
                          >
                            <img
                              src={  product?product.images[1].src
                                      : ""  }
                              alt="Image"
                              title="Image1"
                            />
                          </a>
                        </li>

                        <li
                          className={
                            this.state.productImageName === productImg2
                              ? "active"
                              : ""
                          }
                        >
                          <a
                            onClick={() => {
                              this.handleClick(
                                product?product.images[2].src
                                        : ""
                              );
                            }}
                          >
                            <img
                              src={product?product.images[2].src : ""}
                              alt="Image"
                              title="Image3"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 item-right-block ">
                    <div className="item-title py-5">
                      <h2>
                        {product?product.name : ""}
                      </h2>
                      <div dangerouslySetInnerHTML={{ __html: product?product.description : "" }} />

                    </div>
                    {this.productPrice(product)}

                    <div className="pt-5 btn-block">
                      <button
                        type="button"
                        className="btn btn-lg"
                        onClick={this.addtoCart.bind(this,product)}
                      >
                        Add to cart
                      </button>
                      <span className={"add_to_cart mt-2"}>
                        <h3
                          className={
                            "addcart" + this.state.show ? this.state.show : ""
                          }
                        >
                          {"Adding to cart"}
                        </h3>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="clip_loader">
          <ClipLoader
            sizeUnit={"px"}
            size={80}
            color={"#f62f5e"}
            loading={this.state.loading}
          />
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    cart: state.get("products").cart,
    single_product:state.get("products").single_product,
    token: state.get("user").token,
    showSubCategory: state.get("showSubCategory").showSubCategory,
    woo_products:state.get("products").woo_products,
    wooCategories:state.get("products").wooCategories,
    wooCartProducts:state.get("products").cartWooProducts,
  };
};

const mapStateToDispatch = dispatch => ({
  getSingleWooProduct: data =>
    dispatch(Actions.getSingleWooProduct.request(data)),
  getCartWooProducts: data => dispatch(Actions.getCartWooProducts.request(data))
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(WooProductDetails);
