import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../../images/proof-of-concept.png";
import Image from "react-bootstrap/Image";
import * as Actions from "../../actions";
import { LinkContainer } from "react-router-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Cart from "../Cart/cart";
import { connect } from "react-redux";
import HamburgerMenu from "react-hamburger-menu";
import "../../scss/navbar.scss";
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchitem: "",
      open:false
    };
  }
  componentDidMount(){

  }
  searchItems(e) {
    this.setState({ searchitem: e.target.value });
  }
  clearSub() {
    this.props.setSubCategory(false);
    this.setState({
        open: !this.state.open
    });
  }
  handleClick() {
    this.setState({
        open: !this.state.open
    });

}
  render() {
    let { cart } = this.props;
    if (!cart) cart = { count: 0 };
    let categories=this.props.wooCategories;
    const uniqueCategories = categories.filter(function(elem, pos) {
    return categories.indexOf(elem) === pos;
    });

    return (
      <header className="header bg-white">
        <Container className="head-inner">
          <div className="logo_block">
            <LinkContainer className="logo" to="/">
              <Image
                src={logo}
                className="justify-content-start nav-logo"
                fluid
              />
            </LinkContainer>
          </div>
          <div className="hamburger-menu">All Categories <HamburgerMenu  isOpen={this.state.open}
                                      menuClicked={this.handleClick.bind(this)}
                                      width={18}
                                      height={15}
                                      strokeWidth={1}
                                      rotate={0}
                                      color='black'
                                      borderRadius={0}
                                      animationDuration={0.5}
                                  /></div>
          <Navbar className="" expand="lg">
            <Navbar.Toggle
              data-toggle="collapse"
              data-target="#basic-navbar-nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              aria-controls="basic-navbar-nav"
            />

            <Navbar.Collapse id="basic-navbar-nav" className={this.state.open ?"open":"closed"}>
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0 list-unstyled">
                {uniqueCategories.length &&
                  uniqueCategories.map(category => {
                    if(category.name !== 'UnCategorized'){
                      var link = "/categories/" + category.name.toLowerCase().replace(/ /g, "").replace("&amp;", "-");
                      var location_path =
                        this.props.location.pathname === link ? "active" : "";

                      return (
                        <li
                          className={"nav-item" + " " + location_path}
                          key={category.name}
                        >
                          <LinkContainer to={link} onClick={this.handleClick.bind(this)}>
                            <Nav.Link >
                              {category.name? category.name.replace("&amp;", ""):""}
                            </Nav.Link>
                          </LinkContainer>
                        </li>
                      );
                    }

                  })}
              </ul>
            </Navbar.Collapse>
          </Navbar>
          <div className="head-right">
            <ul className="list-unstyled">
              <li>
                <span className="search_input">
                  <form
                    action={"/search/" + this.state.searchitem}
                    id="search_icon"
                  >
                    <input
                      type="search"
                      placeholder=""
                      className="search"
                      onChange={this.searchItems.bind(this)}
                    />
                  </form>
                </span>
              </li>
              <li>
                <LinkContainer to={"/cart"} className="">
                  <a>
                    <Cart cartItems={this.props.wooCartProducts.length} />
                  </a>
                </LinkContainer>
              </li>
            </ul>
          </div>
          <div className="clearfix" />
        </Container>
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    location: state.get("router").location,
    token: state.get("user").token,
    wooCategories:state.get("products").wooCategories,
    wooCartProducts:state.get("products").cartWooProducts,
  };
};

const mapStateToDispatch = dispatch => ({
});

export default connect(
  mapStateToProps,
  null
)(NavBar);
