import React, { Component } from "react";
import {Row,Col,InputGroup,Form} from "react-bootstrap";
import * as Actions from "../../actions";
import { connect } from "react-redux";
import "../../scss/cart.scss";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from "react-stripe-elements";
import axios from "axios";
import { WOO_API_ROOT } from "../../services/constants";
import { injectStripe } from "react-stripe-elements";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      errors: null,
      paymentOption:null,
      paymentSuccess:false
    };
    this.stage = 2;
  }
  chnagedText(e) {
    let state = this.state;
    state[e.currentTarget.name] = e.currentTarget.value;
    this.setState(state);
  }
  backStage() {
    this.props.backStage(this.stage);
  }
  nextStage() {
    this.props.nextStage(this.stage);
  }
  handleSelect(e){
    this.setState({paymentOption:e.target.id,errors:null });
  }
  componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (this.props.ordersuccess !== prevProps.ordersuccess) {
      if(this.state.paymentOption === "card-payment"){
      this.props.stripe
        .createToken({type: 'card', name:  this.props.user.name})
        .then(({ token }) => {
          if( token ) {
            this.props.stripe.createSource({
                  type: 'card',
                  owner: {
                    name:  this.props.user.name,
                  },
                }).then(({source})=>{
                  let order_id=this.props.wooOrders.id;
                  this.wooCommerceStripePayment( source, order_id );
                });
      		} else {
            this.setState({ errors: "Payment Faild" });
      		}
        });
      }else {
        this.setState({ errors: null });
        this.props.removeFromCart();
        this.props.nextStage(this.stage);
      }
  }
}
  wooCommerceStripePayment(token, order_id){
    var url = "https://woocommerce.believecreative.com/wp-json/wc/v3/stripe_payment";
  	var formData = new FormData();
    let orderData = {
      id:order_id,
      data:{
      payment_method: "stripe",
      payment_method_title: "Payment with Debit/Credit Card",
      status:"completed"
    }};
  	formData.append("order_id", order_id);
  	formData.append("payment_token", token.id);
  	formData.append("payment_method", 'stripe');
  	var request = new XMLHttpRequest();
  	request.open("POST", url);
  	request.send(formData);
  	request.onload = (e) => {
  		if (request.readyState === 4) {
  		  if (JSON.parse(request.response).code === 200) {

          if(JSON.parse(request.response).message==="Your Payment was Successful"){
            this.props.removeFromCart();
            this.props.nextStage(this.stage);
            this.props.setOrderFalse();
            this.setState({ errors: null ,paymentSuccess:true});
          }else {
    		    this.setState({ errors: request ,paymentSuccess:false});
          }
  		  } else {
  		    this.setState({ errors: request });
  		  }
  		}
  	};
  }
  handleSubmit(ev) {
    ev.preventDefault();
    let this_ref = this;
    // We don't want to let default form submission happen here, which would refresh the page.
    let totalAmount = 0;
    if (this.props.cart) {
      let cart = this.props.cart;
      if (cart.products) {
        for (var i = 0; i < cart.products.length; i++) {
          totalAmount =
            totalAmount + cart.products[i].price * cart.products[i].quantity;
        }
      }
    }
    totalAmount = Math.round(totalAmount * 100) / 100;
    console.log(this.state.paymentOption,totalAmount);
    let shippingOrder={};
    let orderData=null;
    let customerDetails=this.props.user.wooCustomerDetails;
    let wooCartProducts=this.props.wooCartProducts
    let orderProducts=[];


    if(this_ref.state.paymentOption){
        wooCartProducts.map((product)=>{
          orderProducts.push({
            product_id:product.product_id,
            quantity: product.quantity});
        });
        var customerid=this.props.wooCustomerDetails.id;
        if(this_ref.state.paymentOption === "card-payment"){
          orderData = {
            payment_method:"stripe",
            payment_method_title:"Pay with Credit/Debit card",
            status:"pending",
            set_paid: false,
            customer_id:customerid,
            billing: customerDetails.billing,
            shipping: customerDetails.shipping,
            line_items: orderProducts,
            shipping_lines: [
              {
                method_id: "flat_rate",
                method_title: "Flat Rate",
                total: this.props.deliveryOption.value
              }
            ]
          };
          console.log(orderData);
          if(orderData){
            this_ref.props.createWooOrder(orderData);
          }

        }else {
          orderData = {
            payment_method:"cod",
            payment_method_title:"Cash On Delivery",
            set_paid:true,
            billing: customerDetails.billing,
            shipping: customerDetails.shipping,
            line_items: orderProducts,
            shipping_lines: [
              {
                method_id: "flat_rate",
                method_title: "Flat Rate",
                total: "20"
              }
            ]
          };
          console.log(orderData);
          if(orderData){
            this_ref.props.createWooOrder(orderData);
          }
        }
    }else {
      this_ref.setState({ errors: "Please Select Payment Option" });
    }

  }
  showErrors() {
    if (this.state.errors) {
      return <div className="alert alert-danger">{this.state.errors}</div>;
    } else {
      return <div />;
    }
  }
  render() {
    console.log(this.state);

    return (
      <React.Fragment>
        {this.showErrors()}
        <Form.Group as={Row} className="payment_block">
        <Col md={12} className="payment_options">
            <Form.Check
            inline
            type="radio"
            label="Cash On Delivery"
            name="payment"
            onChange={this.handleSelect.bind(this)}
            id="cash-on-delivery"
          />
          <Form.Check
            inline
            type="radio"
            label="Pay with Debit/Credit Card"
            name="payment"
            onChange={this.handleSelect.bind(this)}
            id="card-payment"
          />
        </Col>

      <Col md={12} className={""}>
          <Form onSubmit={this.handleSubmit.bind(this)}>
          {this.state.paymentOption == "card-payment" ?
            (<div className="form-content">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="">Card Holder Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={this.state.name}
                    onChange={this.chnagedText.bind(this)}
                    name="name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="">Card Number*</label>
                  <CardNumberElement placeholder="Test card: 4242 4242 4242 4242" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="">Vaild Thru*</label>
                  <CardExpiryElement />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="">CVV/CVC *</label>
                  <CardCVCElement />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group cvv_text">
                  <p className="">
                    *CVV or CVC is the card security code, unique three
                    digits number on the back of your card separate from its
                    number.*
                  </p>
                </div>
              </div>
            </div>
          </div>):""
      }
          <div className="checkout_next">
            <button
              onClick={this.backStage.bind(this)}
              type="button"
              className="btn btn-md btn-white back"
            >
              {this.props.back}
            </button>
            <button type="submit" className="btn btn-md next_step">
              {this.state.paymentOption == "card-payment" ? this.props.next :"Submit"}
            </button>
          </div>
          </Form>
          </Col>
        </Form.Group>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    wooOrders: state.get("order").wooOrders,
    ordersuccess:state.get("order").ordersuccess,
    wooCustomerDetails:state.get("user").wooCustomerDetails
  };
};

const mapStateToDispatch = dispatch => ({
  createWooOrder: data => dispatch(Actions.createWooOrder.request(data)),
  updateWooOrder: data => dispatch(Actions.updateWooOrder.request(data)),
  removeFromCart:data=> dispatch(Actions.removeFromCart()),
  setOrderFalse:data=>dispatch(Actions.setOrderFalse())
});


export default injectStripe(connect(
  mapStateToProps,
  mapStateToDispatch
)(Payment));
