import React, { Component } from "react";
import * as Actions from "../../actions";
import { connect } from "react-redux";
import ProductList from "../../components/Product/productlist";
import "../../scss/categories.scss";
import Pagination from "react-js-pagination";
import { ClipLoader } from "react-spinners";

class ProductCategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }
  componentDidMount() {
    if(this.props.getWooProducts){
      this.props.getWooProducts();
    }
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }
  render() {
    let productsList = [];
    this.props.wooProducts.forEach((item, i) => {
      productsList.push({
        name:item.name,
        slug:item.slug,
        product_id:item.id,
        description:item.description,
        thumbnail: item.images.length ?item.images[0].src : "https://woocommerce.believecreative.in/wp-content/uploads/2020/04/71SVQ71hVL._SL1500_.jpg",
        price:item.regular_price,
        discounted_price:item.sale_price,
        categories:(item.categories.length ? item.categories :[])
      })
    });



    let productCategoriesList = this.props.categoryProducts
      ? Object.values(this.props.categoryProducts)
      : [];
    productCategoriesList.map((category, index) => {
      category.map((item, ind) => {
        productsList.push(item);
        return item;
      });
      return category;
    });

    const categoryName = this.props.match.params.product_category;
    let categoryProducts=[];
    productsList.forEach((product, i) => {
        product.categories.forEach((item, i) => {
          if(item.slug === categoryName){
            categoryProducts.push(product);
          }
        });
    });
    let totalItemsCount = categoryProducts.length;
    categoryProducts = categoryProducts.sort(function(a, b) {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      }
      return 0;
    });
    categoryProducts = categoryProducts.splice((this.state.activePage - 1) * 10, 10);
    return (
      <div className="container">
        <div className="product_filter_panel">
          <div className="row">
            <div className="col-md-12 items_block">
              <div className="pb-3">
                <h2>{categoryName}</h2>
              </div>
              <div>
                {categoryProducts ? (
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    innerClass={"pagination-block pb-4"}
                    prevPageText={"<"}
                    nextPageText={">"}
                    itemClassPrev={"back"}
                    itemClassNext={"forward"}
                    hideFirstLastPages={"false"}
                  />
                ) : (
                  ""
                )}
              </div>
              <section className="category_products">
                {categoryProducts.length > 0 ? (
                  <ProductList products={categoryProducts ? categoryProducts : []} />
                ) : (
                  <div className="clip_loader">
                    <ClipLoader
                      sizeUnit={"px"}
                      size={80}
                      color={"#f62f5e"}
                      loading={this.state.loading}
                    />
                  </div>
                )}
              </section>
              <div>
                {categoryProducts ? (
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    hideFirstLastPages={"false"}
                    innerClass={"pagination-block pb-4"}
                    prevPageText={"<"}
                    nextPageText={">"}
                    itemClassPrev={"back"}
                    itemClassNext={"forward"}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchitem: state.get("products").searchItem,
    token: state.get("user").token,
    cart: state.get("products").cart,
    wooProducts:state.get("products").woo_products,
  };
};

const mapStateToDispatch = dispatch => ({
    getWooProducts: (data) => dispatch(Actions.getWooProducts.request(data)),
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ProductCategoryList);
