import * as ActionTypes from "../actions";

const intialState = {
  loading: false,
  shipping: [],
  wooShippingZones:[],
  wooShippingMethods:[],
  wooShippingZoneMethods:[]
};


export default (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GETWOOSHIPPINGZONES.REQUEST:
        return {
          ...state,
          isLoading: true
        };
    case ActionTypes.GETWOOSHIPPINGZONES.SUCCESS:
        return {
          ...state,
          isLoading: false,
          wooShippingZones: action.response
        };
    case ActionTypes.GETWOOSHIPPINGZONES.FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.errors
        };
    case ActionTypes.GETWOOSHIPPINGMETHODS.REQUEST:
        return {
              ...state,
              isLoading: true,
            };
    case ActionTypes.GETWOOSHIPPINGMETHODS.SUCCESS:
        return {
              ...state,
              isLoading: false,
              wooShippingMethods: action.response
            };
    case ActionTypes.GETWOOSHIPPINGMETHODS.FAILURE:
        return {
              ...state,
              isLoading: false,
              error: action.errors
            };
    case ActionTypes.GETWOOSHIPPINGZONEMETHODS.REQUEST:
        return {
              ...state,
              isLoading: true,
              };
    case ActionTypes.GETWOOSHIPPINGZONEMETHODS.SUCCESS:
        return {
              ...state,
              isLoading: false,
              wooShippingZoneMethods: action.response
              };
    case ActionTypes.GETWOOSHIPPINGZONEMETHODS.FAILURE:
        return {
              ...state,
              isLoading: false,
              error: action.errors
             };
    default:
      return state;
  }
};
